import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Alert from "../components/alert"
import HeaderImg from "../images/header_bg_4.png"
import IconWarn from "../images/icon_alert.svg"
import { above, TH2, TPReg, Wrapper } from "../components/global"
import Quote from "../components/quote"

const ContactDetails = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 40px 0;
    max-width: 700px;
    margin: 0 auto;
    ${above.small`
        padding: 120px 0;
    `}
`

const Hours = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
`

const ContactPage = () => (
  <Layout pageTitle="Contact Us" pageText="If you have any questions, please contact us and we will get in touch with you shortly. Alternatively, you can give us a call or even drop by and visit us – we hope to see you soon!" pageBG={HeaderImg}>
    <SEO title="Contact Us" />
    <Alert alertIcon={IconWarn} alertTitle="Covid-19" alertText="We can do contactless payments &amp; email invoices. We disinfect all keys and steering wheels etc before &amp; after we get in your vehicle!" />
    
    <Wrapper>

    <ContactDetails>
        <TH2>Getting In Touch</TH2>
        <TPReg>Mobile: <a href="tel:07549820891" title="Call Us">07549 820 891</a></TPReg>
        <TPReg>Office: <a href="tel:01516326668" title="Call Us">0151 632 6668</a></TPReg>
        <TPReg>1C Carterton Road, Wirral, CH47 4FD, United Kingdom</TPReg>
        <TH2>Business Hours</TH2>
        <Hours>
            <div>
                <TPReg>Mon - Fri</TPReg>
                <TPReg>Saturday</TPReg>
                <TPReg>Sunday</TPReg>
            </div>
            <div>
                <TPReg>8:00 - 17:30</TPReg>
                <TPReg>Closed</TPReg>
                <TPReg>Closed</TPReg>
            </div>
        </Hours>
    </ContactDetails>
        
    </Wrapper>

    <Quote />
  </Layout>
)

export default ContactPage
